import AdminPricingManagementApi from "@/generatedapiclients/src/api/AdminPricingManagementApi.js";

export default class AdminPricingManagementService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminPricingManagementApi = new AdminPricingManagementApi();
  }

  async getDiscountClasses() {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementDiscountClassesGet(
      this._tenantId
    );
    return response.data;
  }

  async updateDiscountClass(discountClass) {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementDiscountClassPut(
      this._tenantId,
      {
        discountClass
      }
    );
    return response;
  }

  async getPricingSeasons() {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsGet(
      this._tenantId
    );
    return response.data;
  }
  async getPricingSeasonsForLocation(locationId) {
    const response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementPricingSeasonsLocationIdGet(
      locationId,
      this._tenantId
    );
    return response;
  }
  async savePricingSeason(pricingSeason) {
    let response = null;
    if (pricingSeason.id) {
      response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPut(
        this._tenantId,
        {
          adminPricingSeasonUpdateRequest: {
            ...pricingSeason
          }
        }
      );
    } else {
      response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementPricingSeasonPost(
        this._tenantId,
        {
          pricingSeason
        }
      );
    }
    return response;
  }

  async saveLocationPricingSeason(pricingSeason) {
    let response = null;
    if (pricingSeason.id) {
      response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPut(
        this._tenantId,
        {
          adminPricingSeasonUpdateRequest: {
            ...pricingSeason
          }
        }
      );
    } else {
      response = await this._adminPricingManagementApi.v1TenantTenantIdAdminAdminPricingManagementLocationPricingSeasonPost(
        this._tenantId,
        {
          pricingSeason
        }
      );
    }
    return response;
  }
}
